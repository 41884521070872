exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-desktop-index-js": () => import("./../../../src/pages/desktop/index.js" /* webpackChunkName: "component---src-pages-desktop-index-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-home-myhealthbook-js": () => import("./../../../src/pages/home-myhealthbook.js" /* webpackChunkName: "component---src-pages-home-myhealthbook-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-main-js": () => import("./../../../src/pages/main.js" /* webpackChunkName: "component---src-pages-main-js" */),
  "component---src-pages-my-health-book-js": () => import("./../../../src/pages/MyHealthBook.js" /* webpackChunkName: "component---src-pages-my-health-book-js" */),
  "component---src-pages-pager-js": () => import("./../../../src/pages/pager.js" /* webpackChunkName: "component---src-pages-pager-js" */),
  "component---src-pages-sa-clinic-js": () => import("./../../../src/pages/SA-Clinic.js" /* webpackChunkName: "component---src-pages-sa-clinic-js" */),
  "component---src-pages-sa-feedback-js": () => import("./../../../src/pages/sa-feedback.js" /* webpackChunkName: "component---src-pages-sa-feedback-js" */),
  "component---src-pages-sa-hospital-js": () => import("./../../../src/pages/SA-Hospital.js" /* webpackChunkName: "component---src-pages-sa-hospital-js" */),
  "component---src-pages-scroll-snap-index-js": () => import("./../../../src/pages/scroll-snap/index.js" /* webpackChunkName: "component---src-pages-scroll-snap-index-js" */),
  "component---src-pages-scroll-test-js": () => import("./../../../src/pages/scroll-test.js" /* webpackChunkName: "component---src-pages-scroll-test-js" */),
  "component---src-pages-scroller-index-js": () => import("./../../../src/pages/scroller/index.js" /* webpackChunkName: "component---src-pages-scroller-index-js" */),
  "component---src-pages-smart-assistant-js": () => import("./../../../src/pages/smart-assistant.js" /* webpackChunkName: "component---src-pages-smart-assistant-js" */),
  "component---src-pages-smart-rep-js": () => import("./../../../src/pages/smart-rep.js" /* webpackChunkName: "component---src-pages-smart-rep-js" */),
  "component---src-pages-sr-diagnostics-js": () => import("./../../../src/pages/SR-Diagnostics.js" /* webpackChunkName: "component---src-pages-sr-diagnostics-js" */),
  "component---src-pages-sr-feedback-js": () => import("./../../../src/pages/sr-feedback.js" /* webpackChunkName: "component---src-pages-sr-feedback-js" */),
  "component---src-pages-sr-pharmaceuticals-js": () => import("./../../../src/pages/SR-Pharmaceuticals.js" /* webpackChunkName: "component---src-pages-sr-pharmaceuticals-js" */),
  "component---src-pages-swipeable-js": () => import("./../../../src/pages/swipeable.js" /* webpackChunkName: "component---src-pages-swipeable-js" */),
  "component---src-pages-view-feedback-js": () => import("./../../../src/pages/view-feedback.js" /* webpackChunkName: "component---src-pages-view-feedback-js" */)
}

